// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "M_g8";
export var crossPlatformBusinessSection = "M_hb";
export var crossPlatformCasesSection = "M_hj";
export var crossPlatformExpertiseSection = "M_hg";
export var crossPlatformIndustriesSection = "M_g9";
export var crossPlatformPreferencesSection = "M_hh";
export var crossPlatformProcessSection = "M_hc";
export var crossPlatformQuotesSection = "M_hk";
export var crossPlatformServicesSection = "M_g7";
export var crossPlatformTechStackSection = "M_hd";
export var crossPlatformTechStackSection__callToAction = "M_hf";